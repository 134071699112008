<template lang="pug">
    div
        h1 Test store
        div
            div the localCount is {{localCount}}
            div the count is {{count}}
            div the countPlusLocalState is {{countPlusLocalState}}
            Button(@click="clickCount()") i++
            Button(@click="localClickCount()") local i++
        span
</template>

<script>
    import {mapState,mapGetters,mapMutations,mapActions} from 'vuex';
    export default {
        name: "Test2",
        data: function () {
            return {
                env: process.env,
                localCount: 0
            }
        },
        computed: mapState({
            count: state => state.test.count,
            countPlusLocalState(state){
                return state.test.count + this.localCount;
            }
        }),
        methods:{
            ...mapMutations('test', {
                clickCount: 'increment'
            }),
            localClickCount: function () {
                this.localCount++;
            }
        }
    }
</script>

<style scoped>

</style>